import React from 'react';
import { FluentProvider, webLightTheme } from '@fluentui/react-components';

export default function RootLayout({ children }) {
  return (
    <>
      <FluentProvider theme={webLightTheme}>
        {children}
      </FluentProvider>
    </>
  );
}
